@import url(https://fonts.googleapis.com/css?family=Cairo:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Cairo:400,700&display=swap);
@-webkit-keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0); }
  to {
    transform: translate3d(0, 0, 0); } }
@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes ripple {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0; } }

@keyframes ripple {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0; } }

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

body {
  margin: 0;
  padding: 0;
  font-family: "Cairo", sans-serif; }

#root .button {
  border-radius: 50px;
  text-transform: none;
  box-shadow: none;
  font-weight: bold;
  line-height: 18px;
  padding: 2px;
  height: 50px;
  min-width: 40px;
  font-family: "Cairo", sans-serif; }
  #root .button:disabled {
    opacity: .5; }
  #root .button span {
    font-size: 18px; }
  @media (max-width: 767px) {
    #root .button {
      width: 150px;
      margin: 0 auto;
      font-size: 18px; } }

.loading {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 67px;
  height: 67px;
  transform: translate(-33.5px, -33.5px) scale(0.335) translate(33.5px, 33.5px); }
  .loading .circle {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: ripple 1.6s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: ripple 1.6s cubic-bezier(0, 0.2, 0.8, 1) infinite; }
    .loading .circle.secondary {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s; }

.snackbar {
  z-index: 5 !important;
  bottom: 0px !important; }
  .snackbar > div {
    padding: 10px 15px; }
  .snackbar.therapist {
    bottom: 75px !important; }
  @media (min-width: 540px) {
    .snackbar {
      margin: 0 25px; } }
  @media (min-width: 768px) {
    .snackbar.therapist {
      bottom: 110px !important; } }
  .snackbar [role="alertdialog"] {
    font-family: "Cairo", sans-serif;
    margin-bottom: 10px;
    border-radius: 5px;
    color: #333;
    background-color: #fff; }
    .snackbar [role="alertdialog"] button span {
      font-weight: bold; }
    @media (max-width: 768px) {
      .snackbar [role="alertdialog"] {
        font-size: 12px;
        padding: 6px 10px;
        margin-right: 10px;
        margin-left: 10px; } }

.loadingWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .loadingWrapper p {
    font-size: 12px; }

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }
@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes ripple {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0; } }

@keyframes ripple {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0; } }

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

body {
  margin: 0;
  padding: 0;
  font-family: "Cairo", sans-serif; }

#root .button {
  border-radius: 50px;
  text-transform: none;
  box-shadow: none;
  font-weight: bold;
  line-height: 18px;
  padding: 2px;
  height: 50px;
  min-width: 40px;
  font-family: "Cairo", sans-serif; }
  #root .button:disabled {
    opacity: .5; }
  #root .button span {
    font-size: 18px; }
  @media (max-width: 767px) {
    #root .button {
      width: 150px;
      margin: 0 auto;
      font-size: 18px; } }

.loading {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 67px;
  height: 67px;
  -webkit-transform: translate(-33.5px, -33.5px) scale(0.335) translate(33.5px, 33.5px);
          transform: translate(-33.5px, -33.5px) scale(0.335) translate(33.5px, 33.5px); }
  .loading .circle {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: ripple 1.6s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: ripple 1.6s cubic-bezier(0, 0.2, 0.8, 1) infinite; }
    .loading .circle.secondary {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s; }

.snackbar {
  z-index: 5 !important;
  bottom: 0px !important; }
  .snackbar > div {
    padding: 10px 15px; }
  .snackbar.therapist {
    bottom: 75px !important; }
  @media (min-width: 540px) {
    .snackbar {
      margin: 0 25px; } }
  @media (min-width: 768px) {
    .snackbar.therapist {
      bottom: 110px !important; } }
  .snackbar [role="alertdialog"] {
    font-family: "Cairo", sans-serif;
    margin-bottom: 10px;
    border-radius: 5px;
    color: #333;
    background-color: #fff; }
    .snackbar [role="alertdialog"] button span {
      font-weight: bold; }
    @media (max-width: 768px) {
      .snackbar [role="alertdialog"] {
        font-size: 12px;
        padding: 6px 10px;
        margin-right: 10px;
        margin-left: 10px; } }

.loadingWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .loadingWrapper p {
    font-size: 12px; }

html, body {
  height: 100%; }

#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 5px; }
  @media (max-width: 540px) {
    #app {
      padding: 0;
      background-color: white !important;
      display: block; } }
  #app .brand {
    display: block;
    margin: 20px auto;
    width: 250px;
    position: relative;
    width: 280px;
    left: 0;
    top: 0;
    -webkit-transition: .5s;
    transition: .5s;
    -webkit-transition-timing-function: ease-in-out;
            transition-timing-function: ease-in-out; }
    #app .brand.loggedIn {
      width: 125px;
      left: -160px;
      top: -30px; }
      @media (max-width: 540px) {
        #app .brand.loggedIn {
          left: -130px; } }
      @media (max-width: 375px) {
        #app .brand.loggedIn {
          left: -105px; } }
      @media (max-width: 325px) {
        #app .brand.loggedIn {
          left: -80px; } }

#page {
  background-color: white;
  position: relative;
  padding: 25px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 6px;
  min-width: 475px;
  box-sizing: border-box; }
  @media (max-width: 767px) {
    #page .button {
      width: 100%; } }
  @media (max-width: 540px) {
    #page {
      width: 100%;
      min-width: 0;
      box-shadow: none; } }

.formWrapper.login {
  margin-bottom: 25px; }
  .formWrapper.login .loading {
    margin-bottom: -15px; }

.formWrapper.changePassword.isLoading form {
  opacity: .2;
  pointer-events: none; }

.formWrapper.changePassword .loading {
  position: absolute;
  margin-top: 92px; }

.formWrapper.changePassword .loadingWrapper p {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 150px; }

form {
  display: flex;
  flex-direction: column; }
  form.disabled {
    opacity: 0;
    pointer-events: none;
    position: absolute; }
  form .formGroup {
    margin: 10px 0; }
    form .formGroup label, form .formGroup input {
      font-family: "Cairo", sans-serif; }
  form button[type="submit"] {
    margin: 20px 0;
    width: 100%; }

footer {
  font-size: 14px;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 480px) {
    footer {
      padding: 20px 0px 0px; } }
  footer a {
    margin: 0 10px; }

.buttonLink {
  font-size: 14px;
  -webkit-appearance: listitem;
  background: none;
  border: none;
  font-family: "Cairo", sans-serif;
  cursor: pointer; }
  .buttonLink.logout {
    background-image: url(/static/media/logout.21d03ea3.svg);
    position: absolute;
    right: 15px;
    top: 22px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: .8;
    -webkit-transition: opacity .3s;
    transition: opacity .3s; }
    .buttonLink.logout:hover {
      opacity: 1; }

.inlineError {
  background-image: url(/static/media/exclamation-mark.fbd33943.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 15px;
  padding-left: 20px;
  color: #E6655B;
  font-style: italic;
  font-size: 12px;
  -webkit-animation: slideInDown .3s;
          animation: slideInDown .3s; }

.success {
  max-width: 500px;
  margin: 0 auto 30px auto;
  text-align: center;
  line-height: 24px; }

